import axios from 'axios'

window.axios = axios

// SET CSRF TOKEN
let csrfToken = document.head.querySelector('meta[name="csrf-token"]')
if (csrfToken) {
	window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.content
} else {
	console.error('CSRF token not found.')
}

const state = {
	commerceCart: {},
	cart: false,
	calculating: false,
	cartCount: 0,
	coupon: '',
	couponActive: false,
	shippingMethodHandle: false,
	shippingSameAsBilling: true
}

const mutations = {
	setCommerceCart(state, commerceCart) {
		state.commerceCart = commerceCart
	},
	setCoupon(state, coupon) {
		state.coupon = coupon
	},
	setCartCount(state, totalQty) {
		state.cartCount = totalQty
	},
	setLineItems(state, lineItems) {
		state.cart = Object.keys(lineItems).length > 0 ? lineItems : false
	},
	SETQTYLINEITEM(state, payload) {
		state.cart[payload.index].qty = Number(payload.qty)
	},
	deleteLineItem(state, key) {
		delete state.cart[key]
	},
	incrementItemQty(state, payload) {
		state.cart[payload.index].qty = Number(state.cart[payload.index].qty) + Number(payload.qty)
	},
	toggleCalculationStatus(state) {
		state.calculating = !state.calculating
	},
	updateCoupon(state, coupon) {
		state.coupon = coupon
	},
	setCouponActive(state, active) {
		state.couponActive = active
	},
	setShippingMethod(state) {
		state.shippingMethodHandle = "freeShipping"
	}
}

const actions = {

	getCart: async (context) => {
		// Determine language
		//const prefix = (document.documentElement.lang !== 'nl') ? '/' + document.documentElement.lang : '';
		await window.axios({
			method: 'get',
			url: '/checkout/get-cart',
			responseType: 'json',
		}).then((response) => {

			let cart = response.data.cart
			context.commit('setCommerceCart', cart)
			context.commit('setCoupon', cart.couponCode === null ? '' : cart.couponCode)
			context.commit('setCouponActive', cart.couponCode !== null)
			context.commit('setLineItems', cart.lineItems)
			context.commit('setCartCount', cart.lineItems ? cart.lineItems.length : 0)
			context.commit('setShippingMethod')
		})
	},

	updateCommerce: async (context) => {
		context.commit('toggleCalculationStatus')

		var formData = new FormData()

		formData.set('CRAFT_CSRF_TOKEN', window.axios.defaults.headers.common['X-CSRF-TOKEN'])
		formData.set('action', 'commerce/cart/update-cart')
		Object.keys(context.state.cart).forEach((key) => {
			if (context.state.cart[key].qty > 0) {
				formData.set('lineItems[' + context.state.cart[key].id + '][qty]', context.state.cart[key].qty)
			} else {
				formData.set('lineItems[' + context.state.cart[key].id + '][remove]', 1)
				context.commit('deleteLineItem', key)
			}
		})

		formData.set('couponCode', context.state.coupon)
		formData.set('shippingMethodHandle', 'freeShipping')
		formData.set('shippingAddressSameAsBilling', '1')

		if (context.state.coupon) {
			context.commit('setCouponActive', true)
		} else {
			context.commit('setCouponActive', false)
		}

		await window.axios({
			method: 'post',
			headers: {
				"Content-Type": 'application/x-www-form-urlencoded',
			},
			data: formData,
			url: '',
		}).then(async (response) => {
			await context.dispatch('getCart')
		}).finally(resp => {
			context.commit('toggleCalculationStatus')
		})
	},

	incrementItemQty: (context, payload) => {
		context.commit('incrementItemQty', payload)
		context.dispatch('updateCommerce')
	},

	qtyChange: (context, payload) => {
		context.dispatch('updateCommerce')
	},

	setQtyLineItem: async (context, payload) => {
		await context.commit('SETQTYLINEITEM', payload)
		await context.dispatch('updateCommerce')
	},

	deleteLineItem: (context, payload) => {
		payload.qty = 0
		context.commit('SETQTYLINEITEM', payload)
		context.dispatch('updateCommerce')
	},

	deleteConfiguredProduct: (context, item) => {

		// Set Subitem qty to 0
		item.orderOptions.subLineItems.forEach(subItem => {
			let index = context.state.cart.findIndex(object => object.id === subItem.id)
			context.commit('SETQTYLINEITEM', {
				index: index,
				qty: 0
			})
		})

		// Set main product qty to 0
		let index = context.state.cart.findIndex(object => object.id === item.id)
		context.commit('SETQTYLINEITEM', {
			index: index,
			qty: 0
		})

		context.dispatch('updateCommerce')

	},

	setShippingMethod: async (context, payload) => {
		await context.commit('setShippingMethod')
		await context.dispatch('updateCommerce')
	}

}

const getters = {}

const setters = {}

export default {
	namespaced: true,
	state,
	getters,
	setters,
	actions,
	mutations,
}