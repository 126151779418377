const state = {
	wishlistProducts: [],
	wishlistRecipes: []
}

const mutations = {
	LOAD_WISHLIST(state, [ids, list]) {
		list === 'products' ? state.wishlistProducts = ids : state.wishlistRecipes = ids;
		
	},
	
	ADD_TO_WISHLIST(state, [id, list]) {
		if (list === 'products') {
			if (state.wishlistProducts.includes(id)) {
				state.wishlistProducts.splice(state.wishlistProducts.indexOf(id), 1);
			} else {
				state.wishlistProducts.push(id);
			}
			
			const parsed = JSON.stringify(state.wishlistProducts);
			localStorage.setItem('wishlistProducts', parsed);
		}
		
		if (list === 'recipes') {
			if (state.wishlistRecipes.includes(id)) {
				state.wishlistRecipes.splice(state.wishlistRecipes.indexOf(id), 1);
			} else {
				state.wishlistRecipes.push(id);
			}
			
			const parsed = JSON.stringify(state.wishlistRecipes);
			localStorage.setItem('wishlistRecipes', parsed);
		}
	}
}

const actions = {
	loadWishlists({ commit }) {
		if (localStorage.getItem('wishlistProducts')) {
			try {
				const ids = JSON.parse(localStorage.getItem('wishlistProducts'));
				commit("LOAD_WISHLIST", [ids, 'products']);
			} catch (e) {
				localStorage.removeItem('wishlistProducts');
			}
		}
		
		if (localStorage.getItem('wishlistRecipes')) {
			try {
				const ids = JSON.parse(localStorage.getItem('wishlistRecipes'));
				commit("LOAD_WISHLIST", [ids, 'recipes']);
			} catch (e) {
				localStorage.removeItem('wishlistRecipes');
			}
		}
	},
	
	addToWishlist({ commit }, [id, list]) {
		commit("ADD_TO_WISHLIST", [id, list]);
	}
}

const getters = {
	getProductWishlist (state) {
		return state.wishlistProducts;
	},
	
	getRecipeWishlist (state) {
		return state.wishlistRecipes;
	},
	
	getWishlistCount (state) {
		return state.wishlistProducts.length + state.wishlistRecipes.length;
	},
	
	isOnWishlist: state => ([id, list]) => {
		return list === 'products' ? state.wishlistProducts.includes(id) : state.wishlistRecipes.includes(id);
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}
