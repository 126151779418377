import Vue from 'vue'
import VModal from 'vue-js-modal'
import Configure from '../vue/Configure.vue'

import store from './store'
import { EventBus } from "./event-bus"

Vue.use(VModal, {dynamic: true, dynamicDefaults: {clickToClose: false}, injectModalsContainer: true})

export const Checkout = {

	data: {
		loginName: '',
		password: '',
		newPassword: '',
		currentPassword: '',
		firstName: '',
		lastName: '',
		email: '',
		street: '',
		streetNumber: '',
		zipcode: '',
		city: '',
		country: '',
		businessName: '',
		phone: '',
		businessTaxId: '',
		businessTaxIdPending: false,
		agreedGeneralConditions: false,
		agreedPrivacyPolicy: false,
		agreedNewsletter: false,
		isBillingSameAsShipping: true,
		shippingAddressId: null,
		billingAddressId: null,
		newAddress: false,
		newShippingAddress: false,
		newBillingAddress: false,
		shippingAddressIsBusiness: true,
		billingAddressIsBusiness: true,
		addressIsBusiness: true,
		shippingAddress: {
			isBusiness: true
		},
		billingAddress: {
			isBusiness: true
		},
		address: {
			isBusiness: true
		},
		billingAddressSameAsShipping: false
	},

	mounted() {
		let self = this
		// Dynamically set data objects shippingAddress & billingAddress
		// Set initial values that come from server via Twig
		let elements = document.querySelectorAll('[data-initial]')
		if (elements.length) {
			elements.forEach(function (element) {
				let value = self.$refs[element.id].dataset.initial
				self[element.id] = value
			})
		}
	},

	components: {
		'password-field': () => import(/* webpackChunkName: "password-field" */ '../vue/PasswordField.vue'),
		'cart-teaser': () => import(/* webpackChunkName: "cart-teaser" */ '../vue/CartTeaser.vue'),
		'cart-teaser-small': () => import(/* webpackChunkName: "cart-teaser-small" */ '../vue/CartTeaserSmall.vue'),
		'cart': () => import(/* webpackChunkName: "cart" */ '../vue/Cart.vue'),
		'notification': () => import(/* webpackChunkName: "cart-teaser" */ '../vue/Notification.vue'),
		'modal-default': () => import(/* webpackChunkName: "modal-default" */ '../vue/ModalDefault.vue'),
		'modal-iframe': () => import(/* webpackChunkName: "modal-iframe" */ '../vue/ModalIframe.vue'),
		Configure
	},

	methods: {

		onSubmit(e) {
			this.$refs.form.submit()
		},

		submitAddress(event) {
			let formData = new FormData(event.target)
			axios.post('/', formData)
				.then(response => {
					parent.location.reload()
				})
		},

		toggleNewAddress(objectName, model) {
			this[objectName] = !this[objectName]
			// Set model addressId to null
			this[model + 'Id'] = false
		},

		openModal(urlToLoad) {
			this.$modal.show('modal-default', {'urlToLoad': urlToLoad})
		},

		closeModal() {
			this.$modal.hide('modal-default')
		},

		toggleBusinessInfo(e) {
			if (Object.keys(e.target.dataset).length) {
				let model = e.target.dataset.model
				let handle = e.target.dataset.handle
				this[model + 'IsBusiness'] = this[model][handle]
			}
		},

		async addToCart(e, productDataLayer) {

			let formData = new FormData(e.target)
			let productId = formData.get('productId')
			let alertHandlingByPartner = formData.get('alertHandlingByPartner')
			formData.set('shippingMethodHandle', 'freeShipping')

			await window.axios({
				url: '',
				method: 'post',
				data: formData,
				headers: {
					'content-type': 'application/x-www-form-urlencoded',
					'X-Requested-With': 'XMLHttpRequest'
				}
			}).then(response => {
				if (response.status === 200) {
					if (response.data.success) {
						this.$store.dispatch('cart/getCart').then(() => {
							if (productId !== null) {

								// Push datalayer
								window.dataLayer.push({ ecommerce: null });
								window.dataLayer.push({
									event: 'add_to_wishlist',
									ecommerce: {
										items: [productDataLayer]
									}
								});

								this.startConfiguration(productId, alertHandlingByPartner, productDataLayer)
							}
							EventBus.$emit('show-message', {
								messageText: this.msg.success,
								messageType: "success"
							});
						});
					} else {
						let errors = []
						for (const [key, value] of Object.entries(response.data.errors)) {
							value.forEach(error => {
								errors.push(error)
							})
						}
						EventBus.$emit('show-message', {
							messageText: errors.join('<br>'),
							messageType: "error"
						});
					}
				} else {
					EventBus.$emit('show-message', {
						messageText: this.msg.error,
						messageType: "error"
					})
				}
			}).catch(error => {
				console.log('Error: product not added to cart', error)
				EventBus.$emit('show-message', {
					messageText: this.msg.error,
					messageType: "error"
				})
			})
		},

		startConfiguration(productId, alertHandlingByPartner, productDataLayer) {
			var index
			this.$store.state.cart.cart.some((object, i) => {
				if(object.snapshot.productId === productId) return (index = i)
			})
			this.$modal.show(
				Configure,
				{
					index: index,
					alertHandlingByPartner: alertHandlingByPartner,
					productDataLayer: productDataLayer
				},
				{
					adaptive: true,
					scrollable: true,
					focusTrap: true,
					width: '95%',
					maxWidth: 1150,
					height: "auto",
					clickToClose: true,
					classes: ['configure--modal'],
					minHeight: 730
			})
		}
	},

	store

}

