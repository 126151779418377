export const helperMixin = {
    methods: {
        capitalize(string) {
            return string[0].toUpperCase() + string.toLowerCase().slice(1);
        },
    
        round(value, decimals) {
            return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
        },
    
        formatCurrency(amount) {
            const amountFloat = parseFloat(amount, 10);
            switch (window.locale) {
                case 'fr':
                    return `${ amountFloat.toFixed(2) }€`;
                default:
                    return `€${ amountFloat.toFixed(2) }`;
            }
        },
    }
};
