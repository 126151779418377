// Polyfills
import 'promise-polyfill/src/polyfill';

if (window.Element && !Element.prototype.closest) {
	Element.prototype.closest = function (s) {
		let matches = (this.document || this.ownerDocument).querySelectorAll(s),
			i,
			el = this;
		do {
			i = matches.length;
			while (--i >= 0 && matches.item(i) !== el) {
			}
		} while ((i < 0) && (el = el.parentElement));
		return el;
	};
}

// NPM packages
import lozad from 'lozad';
import {disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import Swiper from 'swiper';
import {helperMixin} from './mixins/helper-mixin';
import SlideUpDown from 'vue-slide-up-down';
import {Checkout} from "../../modules/checkoutmodule/src/assetbundles/checkoutmodule/src/js/checkout";
import {createNamespacedHelpers} from 'vuex';
import VModal from 'vue-js-modal';

const {mapGetters} = createNamespacedHelpers('wishlist');
const {mapActions} = createNamespacedHelpers('wishlist');

//translate
import translateResources from '../vue/translations.json'
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';


import {ValidationProvider, ValidationObserver, extend} from 'vee-validate'
import {required, email} from 'vee-validate/dist/rules'
import { checkVAT, countries } from "jsvat"

// Lozad
window.lozadObserver = lozad('.lozad', {
	loaded: el => {
		const cropPos = el.getAttribute('data-crop-pos');
		if (cropPos) {
			const img = el.getElementsByTagName('img');
			if (img) img[0].style.objectPosition = cropPos;
		}
		el.classList.add('loaded');
	}
});

// Import our CSS
import styles from '../css/app.pcss';
import {EventBus} from "../../modules/checkoutmodule/src/assetbundles/checkoutmodule/src/js/event-bus";

// App main
const main = async () => {
	// Async load the vue module
	const {default: Vue} = await import(/* webpackChunkName: "vue" */ 'vue');
	const {default: axios} = await import( /* webpackChunkName: "axios" */ 'axios');
	const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
	const vClickOutside = await import( /* webpackChunkName: "v-click-outside" */ 'v-click-outside');

	Vue.component('validation-provider', ValidationProvider)
	Vue.component('validation-observer', ValidationObserver)

	Vue.use(VueCookies);
	Vue.use(VueI18Next);
	Vue.use(VModal, {dynamic: true, dynamicDefaults: {clickToClose: false}, injectModalsContainer: true})

	await i18next.init({
		lng: document.documentElement.lang,
		debug: true,
		fallbackLng: 'nl',
		whitelist: ['en', 'fr', 'nl'],
		resources: translateResources
	});
	const i18n = new VueI18Next(i18next);

	extend('required', {
		...required,
		message: i18next.t('Dit veld is verplicht in te vullen')
	})

	extend('email', {
		...email,
		message: i18next.t('Gelieve een geldig e-mailadres in te vullen')
	})

	extend('vat', {
		message: i18next.t('Geef een geldig BTW nummer in'),
		validate: value => {
			return checkVAT(value, countries).isValid
		}
	})

	Vue.component('slide-up-down', SlideUpDown);

	window.axios = axios;

	window.maps = [];

	// Create our vue instance
	const vm = new Vue({
		el: "#app",
		delimiters: ['${', '}'],

		i18n: i18n,

		directives: {
			clickOutside: vClickOutside.directive
		},

		mixins: [
			helperMixin,
			Checkout
		],

		components: {
			'webshop-list': () => import(/* webpackChunkName: "webshop-list" */ '../vue/WebshopList.vue'),
			'blog-list': () => import(/* webpackChunkName: "blog-list" */ '../vue/BlogList.vue'),
			'activities-list': () => import(/* webpackChunkName: "activities-list" */ '../vue/ActivitiesList.vue'),
			'recipe-list': () => import(/* webpackChunkName: "recipe-list" */ '../vue/RecipeList.vue'),
			'product-list': () => import(/* webpackChunkName: "product-list" */ '../vue/ProductList.vue'),
			'mini-newsletter-form': () => import(/* webpackChunkName: "mini-newsletter-form" */ '../vue/MiniNewsletterForm.vue'),
			'testimonials-carousel': () => import(/* webpackChunkName: "testimonials-carousel" */ '../vue/TestimonialsCarousel.vue'),
			'stepper-input': () => import(/* webpackChunkName: "stepper-input" */ '../vue/StepperInput.vue'),
			'history-slider': () => import(/* webpackChunkName: "history-slider" */ '../vue/HistorySlider.vue'),
			'frequently-asked-questions': () => import(/* webpackChunkName: "frequently-asked-questions" */ '../vue/FrequentlyAskedQuestions.vue'),
			'tag-snippet': () => import(/* webpackChunkName: "tag-snippet" */ '../vue/TagSnippet.vue'),
			'side-nav': () => import(/* webpackChunkName: "side-nav" */ '../vue/SideNav.vue'),
			'product-wishlist': () => import(/* webpackChunkName: "product-wishlist" */ '../vue/ProductWishlist.vue'),
			'recipe-wishlist': () => import(/* webpackChunkName: "recipe-wishlist" */ '../vue/RecipeWishlist.vue'),
			'recipe-rating': () => import(/* webpackChunkName: "recipe-rating" */ '../vue/RecipeRating.vue')
		},

		data: {
			// Cookie consent
			showCookieConsent: false,
			showStatusBar: true,

			// Language switcher
			languageSwitcherOpen: false,

			// Navigation
			activeSegments: [],
			mobileNavIsOpen: false,
			hideMobileNav: false,
			lastScrollTop: 0,
			showCartTeaser: false,
			mastheadHeight: 0,
			fixateNav: false,
			hideMasthead: false,
			activeNavElementId: null,

			// Recipes
			recipeServingQuantity: 1,
			ingredientQuantity: 0,
			servingQuantity: 0,
			ingredientsTextCopied: false,
			recipeProductModalProductId: null,
			recipeProductModalProductVariantId: null,

			msg: {
				'success': i18next.t('Product toegevoegd aan mandje'),
				'error': i18next.t('Er is iets fout gegaan, probeer opnieuw, aub!')
			}
		},

		created() {
			window.addEventListener('scroll', this.onWindowScroll);
			window.addEventListener('resize', this.onWindowResize);
		},

		mounted() {
			if (window.activeNavElementId) this.activeNavElementId = window.activeNavElementId;
			
			// Init formie
			if (window.Formie) {
				window.Formie.initForms();
			}

			window.lozadObserver.observe();

			// Set active navigation highlight
			const strSegments = window.location.pathname;
			this.activeSegments = strSegments.split('/').slice(1); // First segment is empty

			// Load product wishlist (needed for navigation wishlist count)
			this.loadWishlists();

			// Init blocks
			this.initBlockTextAndImage();
			this.initMaps();

			// Form labels
			this.initForms();

			// Set CSRF token
			const csrfToken = document.head.querySelector('meta[name="csrf-token"]')
			if (csrfToken) {
				window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.content;
			} else {
				console.error('CSRF token not found.')
			}

			// Set locale
			const locale = document.head.querySelector('meta[name="locale"]')
			if (locale) {
				window.locale = locale.content;
			} else {
				console.error('locale not found.')
			}

			// Set siteId
			const siteId = document.head.querySelector('meta[name="siteId"]')
			if (siteId) {
				window.siteId = siteId.content;
			} else {
				console.error('siteId not found.')
			}
		},

		beforeDestroy() {
			window.removeEventListener('scroll', this.onWindowScroll);
			window.removeEventListener('resize', this.onWindowResize);
		},

		methods: {
			onWindowScroll() {
				let scrollTop = window.pageYOffset;

				let navHeight = 0;
				if (this.$refs.navFixWrapper) navHeight = this.$refs.navFixWrapper.clientHeight;

				if (!this.mobileNavIsOpen) {
					this.fixateNav = window.pageYOffset > 160;
					this.hideMobileNav = (scrollTop > this.lastScrollTop) && (scrollTop > navHeight);
					this.lastScrollTop = scrollTop;
				}
			},

			onWindowResize() {
				this.initBlockTextAndImage();
			},

			toggleMobileNav() {
				this.mobileNavIsOpen = !this.mobileNavIsOpen;
				this.mobileNavIsOpen ? disableBodyScroll(this.$refs.navMobileContent) : enableBodyScroll(this.$refs.navMobileContent);
			},

			closeStatusBar(slug) {
				VueCookies.set(`status-${slug}`, 'hidden', '7d');
				this.showStatusBar = false;
			},

			toggleLanguageSwitcher() {
				this.languageSwitcherOpen = !this.languageSwitcherOpen;
			},

			closeLanguageSwitcher() {
				this.languageSwitcherOpen = false;
			},

			initForms() {
				let formFields = document.querySelectorAll('.fui-field.animated');
				formFields.forEach(el => {
					const label = el.querySelector('label');
					const field = el.querySelector('input, textarea');
					if(field) {
						const placeholder = field.placeholder;
						
						field.addEventListener('focus', event => {
							event.target.placeholder = '';
							label.classList.add('show');
						});
						
						field.addEventListener('blur', event => {
							let target = event.target;
							target.placeholder = placeholder !== '' ? this.capitalize(placeholder) : placeholder;
							label.classList.remove('show');
						});
					}
				});
			},

			initBlockTextAndImage() {
				const blocksTextAndImage = document.querySelectorAll('.block-text-and-image.make-it-pop');
				for (let block of blocksTextAndImage) {
					const image = block.querySelector('.block-text-and-image__image');
					const text = block.querySelector('.block-text-and-image__text');
					image.style.height = text.clientHeight + 'px';
				}
			},

			toggleCartTeaser() {
				this.showCartTeaser = !this.showCartTeaser;
			},

			setServingQuantity(serving) {
				this.recipeServingQuantity = serving;
			},

			copyText(ref) {
				const textarea = this.$refs[ref];
				textarea.focus();
				textarea.select();

				try {
					const successful = document.execCommand('copy');
					const msg = successful ? 'successful' : 'unsuccessful';
					this.ingredientsTextCopied = true;
				} catch (err) {
					console.log('Oops, unable to copy');
				}
			},

			initMaps() {
				let maps = document.querySelectorAll('.map')
				// Do we have a map?
				if (maps.length) {
					maps.forEach(element => {

						let lat = element.getAttribute('data-lat')
						let lng = element.getAttribute('data-lng')
						let icon = element.getAttribute('data-icon')

						if (lat && lng) {
							// Set MapBox token
							mapboxgl.accessToken = 'pk.eyJ1IjoidGhlY3JhZnQiLCJhIjoiY2s5dmU2emczMDY5NTNmcW94bXBwcGlkMiJ9.3qofDZi4khMz0icxoCrTFQ'

							// Initiate maps
							let map = new mapboxgl.Map({
								container: element,
								style: 'mapbox://styles/thecraft/ck9vg292p0qvw1inu2zj4cio0',
								center: [lng, lat],
								zoom: 12
							}).addControl(new mapboxgl.NavigationControl())

							if (icon) {
								// Create marker as HTML (isn't this fucking awesome!)
								var marker = document.createElement('div')
								marker.className = 'marker'
								marker.style.backgroundImage = 'url(' + icon + ')'
								// Set marker
								new mapboxgl.Marker(marker).setLngLat([lng, lat]).addTo(map)
							} else {
								// Use default marker
								new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map)
							}

							window.maps.push(map);
						}

					})
				}
			},

			showProductModal(productId, variantId, urlBedrijven, urlVerenigingen) {
				this.$modal.show(ProductModal, {
					productId: productId,
					defaultVariantId: variantId,
					urlBedrijven: urlBedrijven,
					urlVerenigingen: urlVerenigingen
				}, {
					name: 'product-modal',
					height: "auto",
					width: "90%",
					scrollable: true,
					adaptive: true,
					'max-width': 1200
				});
			},

			...mapActions([
				'loadWishlists',
				'addToWishlist'
			])
		},

		computed: {
			...mapGetters([
				'getWishlistCount',
				'isOnWishlist'
			])
		}
	});

	return vm;
};

// Execute async function
main().then((vm) => {
	document.body.classList.add('loaded')
});

window.addEventListener("load", () => {
	initBlockImageCarousel();
	initBlockArticleCarousel();
	initBlockProductCarousel();
	initBlockInstagram();
	initPackshotCarousel();
	resizeMaps(); // Chrome resize bug
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}

function initPackshotCarousel() {
	let carousel = document.getElementById('packshots-swiper');
	if (carousel) {
		var carouselWrapper = new Swiper(carousel, {
			speed: 400,
			spaceBetween: 36,
			autoplay: {
				enabled: false
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true
			}
		});
	}
}

function initBlockProductCarousel() {
	const blocksProducts = document.querySelectorAll('.block-product-carousel');
	for (let block of blocksProducts) {
		const swipeEl = (block.getElementsByClassName('swiper-container-product-carousel').length) ? block.getElementsByClassName('swiper-container-product-carousel')[0] : false;
		if (swipeEl) {
			const btnNext = (block.getElementsByClassName('swiper-next').length) ? block.getElementsByClassName('swiper-next')[0] : false;
			const btnPrev = (block.getElementsByClassName('swiper-prev').length) ? block.getElementsByClassName('swiper-prev')[0] : false;
			new Swiper(swipeEl, {
				preloadImages: false,
				lazy: true,
				loadPrevNext: true,
				loadOnTransitionStart: true,
				loop: true,
				spaceBetween: 36,
				speed: 1000,
				watchOverflow: true,
				watchSlidesVisibility: true,
				slidesPerView: 1,
				autoplay: {
					delay: 5000,
					disableOnInteraction: false
				},
				navigation: {
					nextEl: btnNext,
					prevEl: btnPrev
				},
				breakpoints: {
					// when window width is >= 768px
					768: {
						slidesPerView: 3,
						watchSlidesVisibility: true
					},
					1024: {
						slidesPerView: 4,
						watchSlidesVisibility: true
					},
					1280: {
						slidesPerView: 5,
						watchSlidesVisibility: true
					}
				}
			});
		}
	}
}

function initBlockArticleCarousel() {
	const blocksArticles = document.querySelectorAll('.swiper-container-article-carousel');
	for (let block of blocksArticles) {
		const btnNext = block.closest('.swiper-container-wrapper').getElementsByClassName('swiper-next')[0];
		const btnPrev = block.closest('.swiper-container-wrapper').getElementsByClassName('swiper-prev')[0];
		new Swiper(block, {
			loop: true,
			spaceBetween: 36,
			speed: 1000,
			roundLengths: true,
			watchOverflow: true,
			slidesPerView: 1.2,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false
			},
			navigation: {
				nextEl: btnNext,
				prevEl: btnPrev,
			},

			breakpoints: {
				// when window width is >= 768px
				768: {
					slidesPerView: 2
				},
				1024: {
					slidesPerView: 3
				},
				1280: {
					slidesPerView: 4
				},
				1440: {
					slidesPerView: 5
				}
			}
		});
	}
}

function initBlockImageCarousel() {
	const blocksImageCarousel = document.querySelectorAll('.swiper-container-image-carousel');
	for (let block of blocksImageCarousel) {
		const btnNext = block.getElementsByClassName('swiper-next')[0];
		const btnPrev = block.getElementsByClassName('swiper-prev')[0];
		new Swiper(block, {
			preloadImages: false,
			lazy: true,
			loadPrevNext: true,
			loadOnTransitionStart: true,
			spaceBetween: 10,
			loop: true,
			speed: 1000,
			roundLengths: true,
			watchOverflow: true,
			centeredSlides: true,
			watchSlidesVisibility: true,
			slidesPerView: 1.2,
			autoplay: {
				delay: 5000,
				disableOnInteraction: false
			},
			navigation: {
				nextEl: btnNext,
				prevEl: btnPrev,
			},

			breakpoints: {
				// when window width is >= 768px
				768: {
					slidesPerView: 2.2
				},
				1024: {
					slidesPerView: 2.5
				},
				1280: {
					slidesPerView: 3.5
				},
				1920: {
					slidesPerView: 4
				}
			}
		});
	}
}

function initBlockInstagram() {
	const blocksInstagram = document.querySelectorAll('.block-instagram');

	for (let block of blocksInstagram) {
		let swipeEl = (block.getElementsByClassName('swiper-container-instagram').length) ? block.getElementsByClassName('swiper-container-instagram')[0] : false;
		if (swipeEl) {
			const btnNext = (block.getElementsByClassName('swiper-next').length) ? block.getElementsByClassName('swiper-next')[0] : false;
			const btnPrev = (block.getElementsByClassName('swiper-prev').length) ? block.getElementsByClassName('swiper-prev')[0] : false;

			new Swiper(swipeEl, {
				loop: true,
				spaceBetween: 36,
				speed: 1000,
				roundLengths: true,
				watchOverflow: true,
				slidesPerView: 1.2,
				autoplay: {
					delay: 5000,
					disableOnInteraction: false
				},
				navigation: {
					nextEl: btnNext,
					prevEl: btnPrev,
				},

				breakpoints: {
					// when window width is >= 768px
					768: {
						slidesPerView: 2
					},
					1024: {
						slidesPerView: 3
					},
					1280: {
						slidesPerView: 4
					}
				}
			});
		}

	}
}

function resizeMaps() {
	window.maps.forEach(map => {
		map.resize();
	})
}
