import Vue from 'vue'
import Vuex from 'vuex'
import cart from './modules/cart.js'
import wishlist from './modules/wishlist.js'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
	modules: {
		cart,
		wishlist
	},
	strict: debug,
	plugins: []
})
